import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {MotionPathPlugin} from 'gsap/MotionPathPlugin';
import {DrawSVGPlugin} from './lib/gsap/DrawSVGPlugin.js';
import MicroModal from 'micromodal';
import Plyr from 'plyr';
import Cookies from 'js-cookie';

window.gsap = gsap;
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin);
window.ScrollTrigger = ScrollTrigger;
window.MotionPathPlugin = MotionPathPlugin;
window.Plyr = Plyr;
window.Cookies = Cookies;
window.MicroModal = MicroModal;
